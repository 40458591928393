import React from 'react';

const Header = () => {
  return (
    <header className="bg-blue-magic-900 text-white py-4 shadow-lg">
      <nav className="container mx-auto flex justify-between items-center">
        <a href="#home" className="text-2xl font-bold hover:text-neon-500 transition-colors">
          Blue Magic 
        </a>
        <ul className="flex space-x-6">
          <li>
            <a href="#meme-collection" className="px-4 py-2 bg-neon-500 text-blue-magic-900 rounded-full hover:bg-neon-300 transition-colors">
              Meme Collection
            </a>
          </li>
          <li>
            <a href="#disclaimer" className="px-4 py-2 bg-neon-500 text-blue-magic-900 rounded-full hover:bg-neon-300 transition-colors">
              Disclaimer
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
