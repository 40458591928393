// src/pages/About.jsx
import React from 'react';
import Header from '../components/Header';

const About = () => {
  return (
    <div>
      <Header />
      <div className="container mx-auto py-8">
        <h1 className="text-4xl font-header text-blue-magic-900 mb-6">
          About the Blue Magic project
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-blue-magic-100 p-6 rounded-lg">
            <h2 className="text-2xl font-header text-blue-magic-900 mb-2">Coin experience</h2>
            <p className="font-body text-blue-magic-700">
              Blue Magic cryptocurrency, by rewarding his holders with 4% daily
              rewards for 5 consecutive days is an unique coin. 
              Our goal is to provide a unique and trippy experience for all holders.
              Nobody should be disapointed by buying our coin.              
            </p>
          </div>
        </div>
      </div>        
      {/* Add more content here */}
    </div>
  );
};

export default About;
