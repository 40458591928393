import React, { useState } from 'react';
import Header from '../components/Header';
import HeroImage from '../assets/new_coin_transparent.png';
import LegendImage from '../assets/legend.png';
import Image1 from '../assets/coin2-2.svg';
import Image2 from '../assets/coin3.svg';
import Image3 from '../assets/coin3-2.svg';
import Image4 from '../assets/coin3-3.svg';
import Image5 from '../assets/coin3-4.svg';
import Image6 from '../assets/new_coin1.png';
import Image7 from '../assets/new_coin2.png';
import Image8 from '../assets/new_coin3.png';
import Image9 from '../assets/coin1-1.png';
import { SocialIcon } from 'react-social-icons';

const Home = () => {
  const [activeImage, setActiveImage] = useState(null);

  const handleImageClick = (image) => {
    setActiveImage(image === activeImage ? null : image);
  };

  return (
    <div className="bg-blue-magic-900 text-white" id="home">
      <Header />
      {/* Hero Section */}
      <div className="hero-section text-center py-20">
        <div className="container mx-auto">
          <h1 className="text-5xl font-bold mb-6">Blue Magic</h1>
          <p className="text-xl mb-8">
            The Cryptocurrency That'll Blow Your Mind.
          </p>
          <img src={HeroImage} alt="Hero" className="mx-auto mb-8" />
          <p className="text-lg font-body mb-8">
            Launched with pump.fun, safe and fair. No rugs, just fun. Experience the trippy world of crypto with Blue Magic, like your mum did in the sixties.
          </p>
          <div className="frank-lucas-section mt-8">
            <h2 className="text-4xl font-bold">The Legend of Frank Lucas</h2>
            <p className="text-lg font-body my-6">
              Inspired by the notorious character from American Gangster, Blue Magic aims to deliver an unforgettable experience. Just like Frank, we're all about making a big impression.
            </p>
            <img src={LegendImage} alt="Frank Lucas Legend" className="mx-auto" />
            <p className="text-lg font-body mt-4">"My coin is Blue Magic, it's a brand name. I stand behind it."</p>
          </div>
          {/* Rainbow Text Section */}
          <div className="text-center py-8">
            <h1 className="text-6xl font-bold rainbow">BUY (AND HOLD) ON PUMP.FUN!</h1>
          </div>
          <div className="social-links flex justify-center mt-8 space-x-4">
            <a href="https://t.me/+5asSq3t6JDpiMDZk" target="_blank" rel="noopener noreferrer" className="social-button bg-white p-4 rounded-lg flex flex-col items-center hover:bg-blue-magic-100 transition-colors">
              <SocialIcon url="https://t.me/+5asSq3t6JDpiMDZk" network="telegram" style={{ height: 50, width: 50 }} className="fixed-icon" />
              <p className="mt-2 text-center text-blue-magic-900">Join the community on Telegram</p>
            </a>
            <a href="https://www.pump.fun/8jes7fUGqed3aHKq9cd4WuCxGJV2oZgjBBz5GhSg7wXa" target="_blank" rel="noopener noreferrer" className="social-button bg-white p-4 rounded-lg flex flex-col items-center hover:bg-blue-magic-100 transition-colors">
              <SocialIcon url="https://www.pump.fun/8jes7fUGqed3aHKq9cd4WuCxGJV2oZgjBBz5GhSg7wXa" network="rss" style={{ height: 50, width: 50 }} className="fixed-icon" />
              <p className="mt-2 text-center text-blue-magic-900">BUY ON PUMP.FUN</p>
            </a>
            <a href="https://dexscreener.com" target="_blank" rel="noopener noreferrer" className="social-button bg-white p-4 rounded-lg flex flex-col items-center hover:bg-blue-magic-100 transition-colors">
              <SocialIcon url="https://dexscreener.com" network="rss" style={{ height: 50, width: 50 }} className="fixed-icon" />
              <p className="mt-2 text-center text-blue-magic-900">DexScreener (coming soon)</p>
            </a>
            <a href="https://x.com/bluemagic1969" target="_blank" rel="noopener noreferrer" className="social-button bg-white p-4 rounded-lg flex flex-col items-center hover:bg-blue-magic-100 transition-colors">
              <SocialIcon url="https://x.com/bluemagic1969" network="twitter" style={{ height: 50, width: 50 }} className="fixed-icon" />
              <p className="mt-2 text-center text-blue-magic-900">Follow us on X</p>
            </a>
          </div>
        </div>
      </div>

      {/* Meme Collection */}
      <div className="container mx-auto py-8" id="meme-collection">
        <h2 className="text-4xl font-bold text-center mb-6">Meme Collection</h2>
        <p className="text-lg text-center mb-4">
          Holders can add new items to the collection. Submit your request to the community!
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className={`meme-wrapper ${activeImage === Image1 ? 'active' : ''}`} onClick={() => handleImageClick(Image1)}>
            <img src={Image1} alt="Meme 1" className="meme-image" />
          </div>
          <div className={`meme-wrapper ${activeImage === Image2 ? 'active' : ''}`} onClick={() => handleImageClick(Image2)}>
            <img src={Image2} alt="Meme 2" className="meme-image" />
          </div>
          <div className={`meme-wrapper ${activeImage === Image3 ? 'active' : ''}`} onClick={() => handleImageClick(Image3)}>
            <img src={Image3} alt="Meme 3" className="meme-image" />
          </div>
          <div className={`meme-wrapper ${activeImage === Image4 ? 'active' : ''}`} onClick={() => handleImageClick(Image4)}>
            <img src={Image4} alt="Meme 4" className="meme-image" />
          </div>
          <div className={`meme-wrapper ${activeImage === Image5 ? 'active' : ''}`} onClick={() => handleImageClick(Image5)}>
            <img src={Image5} alt="Meme 5" className="meme-image" />
          </div>
          <div className={`meme-wrapper ${activeImage === Image9 ? 'active' : ''}`} onClick={() => handleImageClick(Image5)}>
            <img src={Image9} alt="Meme 9" className="meme-image" />
          </div>
          <div className={`meme-wrapper ${activeImage === Image6 ? 'active' : ''}`} onClick={() => handleImageClick(Image5)}>
            <img src={Image6} alt="Meme 6" className="meme-image" />
          </div>
          <div className={`meme-wrapper ${activeImage === Image7 ? 'active' : ''}`} onClick={() => handleImageClick(Image5)}>
            <img src={Image7} alt="Meme 7" className="meme-image" />
          </div>
          <div className={`meme-wrapper ${activeImage === Image8 ? 'active' : ''}`} onClick={() => handleImageClick(Image5)}>
            <img src={Image8} alt="Meme 8" className="meme-image" />
          </div>
          <div className={`meme-wrapper ${activeImage === HeroImage ? 'active' : ''}`} onClick={() => handleImageClick(Image5)}>
            <img src={HeroImage} alt="Meme 10" className="meme-image" />
          </div>
        </div>
      </div>

      {/* Disclaimer */}
      <div className="container mx-auto py-8" id="disclaimer">
        <h2 className="text-2xl font-bold text-center mb-6">Disclaimer</h2>
        <p className="text-center font-body">
          Blue Magic is a meme coin created for fun and entertainment purposes. It is not intended for serious investment. 
          Use at your own risk. We are not responsible for any losses incurred.
        </p>
        <p className="text-center font-body mt-4">
          All content was generated by ChatGPT, so please just complain to OpenAI if something bother you.
        </p>
      </div>
    </div>
  );
};

export default Home;
