import React from 'react';
import Header from '../components/Header';

const Features = () => {
  return (
    <div>
      <Header />
      <div className="container mx-auto py-8">
        <h1 className="text-4xl font-header text-blue-magic-900 mb-6">
          Groovy Features of Blue Magic
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-blue-magic-100 p-6 rounded-lg">
            <h2 className="text-2xl font-header text-blue-magic-900 mb-2">
              4% Daily Rewards
            </h2>
            <p className="font-body text-blue-magic-700">
              Hold Blue Magic coins and earn up to 4% rewards each day for 5
              consecutive days. Collect your rewards or reinvest them for
              compounding gains.
            </p>
          </div>
          {/* Add more feature cards here */}
        </div>
      </div>
    </div>
  );
};

export default Features;
